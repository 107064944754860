/* You can add global styles to this file, and also import other style files */

 @font-face {
    font-family:'HCLTechRoobert-Regular';
    src: url('./assets/HCLTechRoobert-Regular.otf');
}
 
  @font-face {
    font-family:'HCLTechRoobert-ExtraBold';
    src: url('./assets/HCLTechRoobert-ExtraBold.otf');
 }
 
 .login_header {
    font-family: 'HCLTechRoobert-Regular';
	font-weight: bolder;
	font-variant: normal;
	text-transform: none;
	font-style: normal;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 }

.headers {

 font-family: 'HCLTechRoobert-Regular';
 speak-as: never;
 font-style: normal;
 font-weight: bolder;
 font-variant: normal;
 text-transform: none;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 font-size: 1rem;
 }

.common-button {

    width: 7.1875rem;
	font-size: 0.875rem !important;
    font-family: 'HCLTechRoobert-Regular' !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    margin-top: -0.25rem;
    margin-bottom: -0.2rem;
}

 .btnSearch {

    width: 7.1875rem;
    height:1.875rem;
    margin-top: -0.3125rem;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem !important;
	font-family: 'HCLTechRoobert-Regular' !important;
    font-size: 0.875rem !important;
	font-weight: normal !important;
    font-style: normal;
	font-variant: normal;
	text-transform: none; 
}

.required::after{

    content:"*";
    color: red;
}

.thead, tbody, tfoot, tr, td, th {

    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 0.875rem;
}

input::placeholder {

	font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-size: 0.875rem;
}


.control-label {

    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-size: 0.875rem;
    user-select: none;
    -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.labelColons{
    user-select: none;
    -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.btnclass_asset {

    width: 7.1875rem;
	font-size: 0.875rem;
    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
}

.customerHeading
{
	text-align: center;
	font-size: 1.25rem;
	font-weight: bolder;
    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-variant: normal;
    text-transform: none;
}

.search{

    line-height: inherit;
    overflow :visible;
    margin: 0%;
}

input[type="password"]
{
   font-size: 0.875rem;
}

input[type="text"] {
    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    font-weight: normal;
}

.common-msg {
  color: black;
  font-family: 'HCLTechRoobert-Regular';
  font-size: 0.875rem;
  font-style: normal;
 font-weight: normal;
 font-variant: normal;
 text-transform: none;
 user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
}

.spinner {
background-color:rgba(51, 51, 51, 0.8);  
}

.form_error{
    color: red;
	font-size: 0.75rem;
	position: absolute;
	font-family: 'HCLTechRoobert-Regular';
}

.input-content { 
    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 0.875rem;
 }

 .accordian-content{

    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 0.875rem;
 }

textarea::placeholder {
    
    font-family: 'HCLTechRoobert-Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 0.875rem;
}

.cursorPointer{
    cursor: pointer;
}

.btn-primary {
    border-radius: 5px;
	background: linear-gradient(90deg, #5F1FBF 0%, #3D90FF 100%);
	box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
}